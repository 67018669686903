/**
 * Component
 * ================================================== */
img {
  max-width: 100%;
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
picture {
  display: block;
}
.inner {
  max-width: $m;
  margin: 0 auto;
}

p,
ul,
dl,
a {
  font-size: 16px;
}
