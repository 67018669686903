/**
 * Scroll Animation
 * ================================================== */
/* スクロールCSS */
.fadein {
  opacity : 0.1;
  transform : translate(0, 50px);
  transition : all 500ms;
}

.fadein-up {
  transform: translate(0, 50px);
}

.fadein-down {
  transform: translate(0, -50px);
}

.fadein-left {
  transform: translate(-50px, 0);
}

.fadein-right {
  transform: translate(50px, 0);
}

.scrollin {
  opacity: 1;
  transform: translate(0, 0);
}