/**
 * Setting
 * ================================================== */
// media query
$base-content-width: 1140px;
$xxl: 1380px;
$xl: 1280px;
$l: 1024px;
$m: 768px;
$s: 480px;

// space
$space-default: 80px;
$space-xl: 56px;
$space-m: 24px;

// colors
$c-bl: #32C0FF;
$c-gr: #9F9F9F;
$c-bl2: #D1FDFF;
$c-wh: #fff;
$c-bk: #000;