/**
 * Footer
 * ================================================== */
footer {
  background: #fff4e8;
  margin-top: 50px;
  .inc {
    padding: 15px 20px;
    .small {
      font-size: 12px;
      text-align: center;
    }
  }
}
