/**
 * Index
 * ================================================== */

main {
  padding: 0 20px;
}

.cv {
  margin-bottom: 80px;
  text-align: center;
  .btn {
    margin-top: 15px;
    img {
      margin: 0 auto;
      animation: fuwafuwa 1s ease 0s infinite alternate;
      transform-origin: center;
      cursor: pointer;
    }
  }
}
@keyframes fuwafuwa {
  from {
    transform: scale(0.9, 0.9);
  }
  to {
    transform: scale(1, 1);
  }
}

#sec1_title {
  margin-bottom: 30px;
}
#kv {
  margin-bottom: 50px;
}
#cont_1 {
  margin-top: 50px;
  margin-bottom: 50px;
}
#cont_2 {
  margin-top: 38px;
  margin-bottom: 50px;
}
#cont_3 {
  margin-top: 50px;
  margin-bottom: 50px;
}
#cont_4 {
  margin-bottom: 80px;
}
#cont_5 {
  margin-bottom: 80px;
}
#cont_6 {
  margin-bottom: 80px;
}
#cont_7 {
  margin-bottom: 30px;
}
