/**
 * Header
 * ================================================== */
header {
  background: #fff4e8;
  margin-bottom: 30px;
  .inc {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    .logo {
      max-width: 210px;
    }
  }
}
